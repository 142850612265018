import React from 'react'
import { useEffect } from 'react'
import Typography from '../../components/common/Typography'
import { useTrans } from '../../services/i18n'
import FormstackManagement from './components/formstackManagement'
import connect from './connect'
import Loader from '../../components/common/Loader'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import ApiKeyConfiguration from './components/apiKeyConfiguration'
import { useNavigate } from 'react-router-dom'
//import PartnerCode from './components/partnerCode'

import QuoteSettings from './components/quoteSettings'
import NotificationSettings from './components/notificationSettings'
import ExportsConfiguration from './components/exportsConfiguration'
import DefaultSAPRequest from './components/defaultSapRequests'
import DefaultSAMRequest from './components/defaultSamRequests'
import AccountFeatures from './components/accountFeatures'
import { ACCOUNT_FEATURES } from '../../constants/subscriptionSettings'
import CalendlyConfiguration from './components/calendlyConfiguration'

const GlobalSettings = ({
  getFeatures,
  getFormstackForms,
  getDefaultSAPRequests,
  getDefaultSAMRequests,
  pendingFeatures,
  authUser,
}) => {
  const { trans } = useTrans()
  const navigate = useNavigate()

  useEffect(() => {
    getFeatures()
    getFormstackForms()
    getDefaultSAPRequests()
    getDefaultSAMRequests()
  }, [])

  useEffect(() => {
    if (
      authUser &&
      (authUser.role === 'manager' || authUser.role == 'consulting')
    ) {
      navigate('/organizations')
    }
  }, [authUser])

  return (
    <SpacingContainerBase>
      {pendingFeatures && <Loader open={pendingFeatures} />}
      <Typography weight="600" variant="h2" fontFamily="lexend" title>
        {trans('global-settings')}
      </Typography>
      {!pendingFeatures && (
        <>
          <FormstackManagement />
          <DefaultSAPRequest />
          <DefaultSAMRequest />
          <ApiKeyConfiguration />
          <NotificationSettings />
          <ExportsConfiguration />
          <QuoteSettings />
          <CalendlyConfiguration />
        </>
      )}
    </SpacingContainerBase>
  )
}

export default connect(GlobalSettings)
