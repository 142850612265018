import { connect } from 'react-redux'
import { actions } from '../../store/globalSettings'

function mapStateToProps(state) {
  return {
    samApiKey: state.globalSettings.samApiKey,
    exportedItemsAtOnce: state.globalSettings.exportedItemsAtOnce,
    monthlyExportsLimit: state.globalSettings.monthlyExportsLimit,
    acceptQuoteRequests: state.globalSettings.acceptQuoteRequests,
    acceptGrantQuoteRequests: state.globalSettings.acceptGrantQuoteRequests,
    acceptStateLocalQuoteRequests:
      state.globalSettings.acceptStateLocalQuoteRequests,
    coForQuoteRequests: state.globalSettings.coForQuoteRequests,
    // freeFeatures: state.globalSettings.freeFeatures,
    // premiumFeatures: state.globalSettings.premiumFeatures,
    // enterpriseFeatures: state.globalSettings.enterpriseFeatures,
    expiredFeatures: state.globalSettings.expiredFeatures,
    limitedFeatures: state.globalSettings.limitedFeatures,
    advantageFeatures: state.globalSettings.advantageFeatures,
    proFeatures: state.globalSettings.proFeatures,
    freeTrialFeatures: state.globalSettings.freeTrialFeatures,
    sapAdvantageFeatures: state.globalSettings.sapAdvantageFeatures,
    appMaxFeatures: state.globalSettings.appMaxFeatures,
    procurementProFeatures: state.globalSettings.procurementProFeatures,
    tierFeatures: state.globalSettings.tierFeatures,
    formstackForms: state.globalSettings.formstackForms,
    forms: state.globalSettings.forms,
    adjustmentForms: state.globalSettings.adjustmentForms,
    pendingFeatures: state.globalSettings.pendingFeatures,
    currentIdsForms: state.globalSettings.currentIdsForms,
    currentFormDetail: state.globalSettings.currentFormDetail,
    files: state.globalSettings.files,
    pendingDetail: state.globalSettings.pendingDetail,
    keepTrashedNotificationsFor:
      state.globalSettings.keepTrashedNotificationsFor,
    authUser: state.auth,
    defaultSAPRequests: state.globalSettings.defaultSAPRequests,
    defaultSAMRequests: state.globalSettings.defaultSAMRequests,
    calendlyConfiguration: state.globalSettings.calendly,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFeatures: params => dispatch(actions.getFeatures(params)),
    getDefaultSAPRequests: params =>
      dispatch(actions.getDefaultSAPRequests(params)),
    getDefaultSAMRequests: params =>
      dispatch(actions.getDefaultSAMRequests(params)),
    saveFormsUsfcr: params => dispatch(actions.saveFormsUsfcr(params)),
    saveFeatures: params => dispatch(actions.saveFeatures(params)),
    saveDefaultSAPRequests: params =>
      dispatch(actions.saveDefaultSAPRequests(params)),
    saveDefaultSAMRequests: params =>
      dispatch(actions.saveDefaultSAMRequests(params)),
    changeStatusForm: params => dispatch(actions.changeStatusForm(params)),
    changeDefaultSAPRequests: params =>
      dispatch(actions.changeDefaultSAPRequests(params)),
    changeDefaultSAMRequests: params =>
      dispatch(actions.changeDefaultSAMRequests(params)),
    deleteForm: params => dispatch(actions.deleteForm(params)),
    getAdjustmentForms: params => dispatch(actions.getAdjustmentForms(params)),
    getFormstackForms: params => dispatch(actions.getFormstackForms(params)),
    changeCurrentIdsForms: params =>
      dispatch(actions.changeCurrentIdsForms(params)),
    changeCurrentFormDetail: params =>
      dispatch(actions.changeCurrentFormDetail(params)),
    changeFormstackForms: params =>
      dispatch(actions.changeFormstackForms(params)),
    saveFileForm: params => dispatch(actions.saveFileForm(params)),
    deleteFileForm: params => dispatch(actions.deleteFileForm(params)),
    getGlobalSettings: params => dispatch(actions.getGlobalSettings(params)),
    getKeepTrashedNotifications: params =>
      dispatch(actions.getKeepTrashedNotifications(params)),
    updateKeepTrashedNotification: params =>
      dispatch(actions.updateKeepTrashedNotification(params)),
    updateApiKey: params => dispatch(actions.updateApiKey(params)),
    saveCustomization: params => dispatch(actions.saveCustomization(params)),
    updateAcceptQuoteRequests: params =>
      dispatch(actions.updateAcceptQuoteRequests(params)),
    saveExportLimits: params => dispatch(actions.saveExportLimits(params)),
    saveCalendlyConfiguration: params =>
      dispatch(actions.saveCalendlyConfiguration(params)),
    deleteTemplateSAP: params => dispatch(actions.deleteTemplateSAP(params)),
    deleteTemplateSAM: params => dispatch(actions.deleteTemplateSAM(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
