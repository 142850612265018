import { useTrans } from '../../../../services/i18n'
import {
  Paper,
  SpacingContainer,
} from '../../../../components/common/Containers'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../../../contexts/AlertContext'
import { Grid } from '@material-ui/core'
import Typography from '../../../../components/common/Typography'
import { Divider } from '../../../../components/common/Divider'

import connect from '../../connect'
import { Button } from '../../../../components/common/Buttons'
import { TextField } from '../../../../components/common/Inputs'
import ShowTooltip from '../../../TooltipsManagement/components/showTooltip'
import { Box } from '@mui/material'
import { CalendarToday } from '@material-ui/icons'
import { selectUserPermissions, isEnabled } from '../../../../selectors'
import { useSelector } from 'react-redux'

const CalendlyConfiguration = ({
  calendlyConfiguration,
  saveCalendlyConfiguration,
}) => {
  const { trans } = useTrans()
  const { successAlert } = useAlertContext()
  const [isSend, setIsSend] = React.useState(false)
  const permissions = useSelector(selectUserPermissions)

  const [state, setState] = useState({
    advProMeetingSlug: '',
    promMeetingSlug: '',
    advmMeetingSlug: '',
    sapMeetingSlug: '',
  })

  const changeInput = ev => {
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
    })
  }

  const saveSettings = async () => {
    try {
      setIsSend(true)
      await saveCalendlyConfiguration({ ...state })
      setIsSend(false)
      successAlert('Calendly Configuration saved successfully')
    } catch (error) {
      setIsSend(false)
    }
  }

  useEffect(() => {
    setState({
      ...calendlyConfiguration,
    })
  }, [calendlyConfiguration])

  return (
    <SpacingContainer py={2}>
      <Paper component={SpacingContainer} py={3} px="5sm3">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs zeroMinWidth>
            <Typography
              style={{ alignItems: 'center', display: 'flex' }}
              variant="h4"
              weight="500"
              fontFamily="lexend"
            >
              <CalendarToday style={{ marginRight: '5px' }} />{' '}
              {trans('calendly-configuration')}{' '}
              <ShowTooltip sectionToShow="calendly-configuration" />
            </Typography>
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '20px' }} />

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            onChange={changeInput}
            name="advProMeetingSlug"
            type="string"
            value={state.advProMeetingSlug}
            label="ADV_PRO Meeting Slug (applies to Advantage/Pro)"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            onChange={changeInput}
            name="promMeetingSlug"
            type="string"
            value={state.promMeetingSlug}
            label="PROM Meeting Slug (applies to Procurement Pro/APP Max)"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            onChange={changeInput}
            name="advmMeetingSlug"
            type="string"
            value={state.advmMeetingSlug}
            label="ADVM Meeting Slug (applies to SAP Advantage)"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>

        <Box style={{ alignItems: 'center', paddingTop: '20px' }}>
          <TextField
            onChange={changeInput}
            name="sapMeetingSlug"
            type="string"
            value={state.sapMeetingSlug}
            label="SAP Meeting Slug (only visible to Procurement Pro/SAP Advantage)"
            style={{ width: '60%', marginRight: '16px' }}
          />{' '}
        </Box>
        {isEnabled(permissions, 'global-settings', '', 'edit') && (
          <Box
            style={{
              borderTop: `1px solid rgba(78, 89, 120, 0.12)`,
              padding: '2rem 0 0.5rem 0',
              marginTop: '2rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs zeroMinWidth>
                <Button
                  variant="contained"
                  style={{ background: '#17b471', color: '#fff' }}
                  onClick={() => saveSettings()}
                  pending={isSend}
                >
                  {' '}
                  Save Settings
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </SpacingContainer>
  )
}
export default connect(CalendlyConfiguration)
