import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit'
import { API } from '../../constants'
import { application } from '../../services/application'
import { ACCOUNT_FEATURES } from '../../constants/subscriptionSettings'

//State
export const INITIAL_STATE = {
  samApiKey: '',
  freeFeatures: [],
  premiumFeatures: [],
  enterpriseFeatures: [],
  tierFeatures: [],
  formstackForms: [],
  forms: [],
  defaultSAPRequests: [],
  defaultSAMRequests: [],
  ajustmentForms: [],
  files: [],
  currentIdsForms: [],
  currentFormDetail: {
    id: '',
    name: '',
    url: '',
  },
  pendingFeatures: false,
  acceptQuoteRequests: false,
  acceptGrantQuoteRequests: false,
  acceptStateLocalQuoteRequests: false,
  coForQuoteRequests: [],
  keepTrashedNotificationsFor: [],
  exportedItemsAtOnce: 0,
  monthlyExportsLimit: 0,
  calendly: {},
}

const changeCurrentIdsForms = createAction('clientDetail/changeIdsForms')
function onChangeCurrentIdsForms(state, action) {
  return Object.assign(state, {
    currentIdsForms: action.payload,
  })
}

const changeCurrentFormDetail = createAction(
  'clientDetail/changeCurrentFormDetail'
)
function onChangeCurrentFormDetail(state, action) {
  return Object.assign(state, {
    currentFormDetail: action.payload,
  })
}

const changeFormstackForms = createAction('clientDetail/changeFormstackForms')
function onChangeFormstackForms(state, action) {
  return Object.assign(state, {
    formstackForms: action.payload,
  })
}

const getFeatures = createAsyncThunk('clientDetail/getFeatures', params => {
  return application.call(API.GLOBAL_SETTINGS.GET_FEATURES, params)
})
function onGetFeatures(state, action) {
  return Object.assign(state, {
    freeFeatures: action.payload.freeFeatures,
    premiumFeatures: action.payload.premiumFeatures,
    enterpriseFeatures: action.payload.enterpriseFeatures,
    tierFeatures: {
      [ACCOUNT_FEATURES.EXPIRED]: action.payload.expiredFeatures,
      [ACCOUNT_FEATURES.LIMITED]: action.payload.limitedFeatures,
      [ACCOUNT_FEATURES.ADVANTAGE]: action.payload.advantageFeatures,
      [ACCOUNT_FEATURES.PRO]: action.payload.proFeatures,
      [ACCOUNT_FEATURES.FREE_TRIAL]: action.payload.freeTrialFeatures,
      [ACCOUNT_FEATURES.SAP_ADVANTAGE]: action.payload.sapAdvantageFeatures,
      [ACCOUNT_FEATURES.APP_MAX]: action.payload.appMaxFeatures,
      [ACCOUNT_FEATURES.PROCUREMENT_PRO]: action.payload.procurementProFeatures,
    },
    forms: action.payload.forms,
    pendingFeatures: false,
    pendingDetail: false,
  })
}

const getGlobalSettings = createAsyncThunk(
  'globalSettings/getGlobalSettings',
  params => {
    return application.call(API.GLOBAL_SETTINGS.GET_GLOBAL_SETTINGS, params)
  }
)
function onGetGlobalSettings(state, action) {
  return Object.assign(state, {
    samApiKey: action.payload.samApiKey,
    exportedItemsAtOnce: action.payload.exportedItemsAtOnce,
    monthlyExportsLimit: action.payload.monthlyExportsLimit,
    acceptQuoteRequests: action.payload.acceptQuoteRequests,
    acceptGrantQuoteRequests: action.payload.acceptGrantQuoteRequests,
    acceptStateLocalQuoteRequests: action.payload.acceptStateLocalQuoteRequests,
    coForQuoteRequests: action.payload.coForQuoteRequests,
    calendly: action.payload.calendly,
  })
}
//---------DEFAFAULT SAP REQUEST----------------------------
const getDefaultSAPRequests = createAsyncThunk(
  'globalSettings/defaultSAPRequests',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.GET_DEFAULT_SAP_REQUESTS,
      params
    )
  }
)
function onGetDefaultSAPRequests(state, action) {
  return Object.assign(state, {
    defaultSAPRequests: action.payload.defaultSAPRequests,
  })
}

const saveDefaultSAPRequests = createAsyncThunk(
  'globalSettings/saveDefaultSAPRequests',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.SAVE_DEFAULT_SAP_REQUESTS,
      params
    )
  }
)
function onSaveDefaultSAPRequests(state, action) {
  const defaultSAPRequests = action.payload.defaultSAPRequests
  return Object.assign(state, {
    defaultSAPRequests: defaultSAPRequests || state.defaultSAPRequests,
  })
}

const changeDefaultSAPRequests = createAction(
  'clientDetail/changeDefaultSAPRequests'
)
function onChangeDefaultSAPRequests(state, action) {
  return Object.assign(state, {
    formstackForms: action.payload,
  })
}

const deleteTemplateSAP = createAsyncThunk(
  'globalSettings/deleteTemplateSAP',
  params => {
    return application.call(API.GLOBAL_SETTINGS.DELETE_TEMPLATE_SAP, params)
  }
)
function onDeleteTemplateSAP(state, action) {
  let templateCopy = []
  let key = 'forms'
  templateCopy = [...state.defaultSAPRequests]
  key = 'defaultSAPRequests'

  const index = templateCopy.findIndex(i => i.id === action.payload.id)
  templateCopy.splice(index, 1)

  return Object.assign(state, {
    [key]: templateCopy,
  })
}
//---------DEFAFAULT SAM REQUEST----------------------------
const getDefaultSAMRequests = createAsyncThunk(
  'globalSettings/defaultSAMRequests',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.GET_DEFAULT_SAM_REQUESTS,
      params
    )
  }
)
function onGetDefaultSAMRequests(state, action) {
  return Object.assign(state, {
    defaultSAMRequests: action.payload.defaultSAMRequests,
  })
}

const saveDefaultSAMRequests = createAsyncThunk(
  'globalSettings/saveDefaultSAMRequests',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.SAVE_DEFAULT_SAM_REQUESTS,
      params
    )
  }
)
function onSaveDefaultSAMRequests(state, action) {
  const defaultSAMRequests = action.payload.defaultSAMRequests
  return Object.assign(state, {
    defaultSAMRequests: defaultSAMRequests || state.defaultSAMRequests,
  })
}

const changeDefaultSAMRequests = createAction(
  'clientDetail/changeDefaultSAMRequests'
)
function onChangeDefaultSAMRequests(state, action) {
  return Object.assign(state, {
    formstackForms: action.payload,
  })
}

const deleteTemplateSAM = createAsyncThunk(
  'globalSettings/deleteTemplateSAM',
  params => {
    return application.call(API.GLOBAL_SETTINGS.DELETE_TEMPLATE_SAM, params)
  }
)
function onDeleteTemplateSAM(state, action) {
  let templateCopy = []
  let key = 'forms'
  templateCopy = [...state.defaultSAMRequests]
  key = 'defaultSAMRequests'

  const index = templateCopy.findIndex(i => i.id === action.payload.id)
  templateCopy.splice(index, 1)

  return Object.assign(state, {
    [key]: templateCopy,
  })
}

const updateApiKey = createAsyncThunk('globalSettings/updateApiKey', params => {
  return application.call(API.GLOBAL_SETTINGS.UPDATE_API_KEY, params)
})

function onUpdateApiKey(state, action) {
  return Object.assign(state, {
    samApiKey: action.payload.samApiKey,
  })
}

const updateAcceptQuoteRequests = createAsyncThunk(
  'globalSettings/updateAcceptQuoteRequests',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.UPDATE_ACCEPT_QUOTE_REQUESTS,
      params
    )
  }
)
function onUpdateAcceptQuoteRequests(state, action) {
  return Object.assign(state, {
    acceptQuoteRequests: action.payload.acceptQuoteRequests,
    acceptGrantQuoteRequests: action.payload.acceptGrantQuoteRequests,
    acceptStateLocalQuoteRequests: action.payload.acceptStateLocalQuoteRequests,
  })
}

const getAdjustmentForms = createAsyncThunk(
  'clientDetail/getAdjustmentForms',
  params => {
    return application.call(API.GLOBAL_SETTINGS.GET_ADJUSTMENT_FORMS, params)
  }
)

function onGetAdjustmentForms(state, action) {
  return Object.assign(state, {
    adjustmentForms: action.payload.adjustmentForms,
    files: action.payload.files,
    currentFormDetail: action.payload.form,
    pendingDetail: false,
  })
}

const getKeepTrashedNotifications = createAsyncThunk(
  'globalSettings/getKeepTrashedNotification',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.GET_KEEP_TRASHED_NOTIFICATIONS,
      params
    )
  }
)

function onGetKeepTrashedNotifications(state, action) {
  return Object.assign(state, {
    keepTrashedNotificationsFor: action.payload.keepTrashedNotificationsFor,
  })
}

function onUpdateKeepTrashedNotification(state, action) {
  return Object.assign(state, {
    keepTrashedNotificationsFor: action.payload.keepTrashedNotificationsFor,
  })
}

const updateKeepTrashedNotification = createAsyncThunk(
  'globalSettings/updateKeepTrashedNotification',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.UPDATE_KEEP_TRASHED_NOTIFICATIONS,
      params
    )
  }
)

const getFormstackForms = createAsyncThunk(
  'clientDetail/getFormstackForms',
  params => {
    return application.call(API.GLOBAL_SETTINGS.GET_FORMSTACK_FORMS, params)
  }
)
function onGetFormstackForms(state, action) {
  return Object.assign(state, {
    formstackForms: action.payload?.formstackForms?.map(i => ({
      ...i,
      checked: false,
    })),
  })
}

const saveFeatures = createAsyncThunk('globalSettings/saveFeatures', params => {
  return application.call(API.GLOBAL_SETTINGS.SAVE_FEATURES, params)
})
function onSaveFeatures(state, action) {
  const freeFeatures = action.payload.freeFeatures
  const premiumFeatures = action.payload.premiumFeatures

  return Object.assign(state, {
    freeFeatures: freeFeatures ? freeFeatures : state.freeFeatures,
    premiumFeatures: premiumFeatures ? premiumFeatures : state.premiumFeatures,
  })
}

const saveFileForm = createAsyncThunk('globalSettings/saveFileForm', params => {
  return application.call(API.GLOBAL_SETTINGS.SAVE_FILE_FORM, params)
})
function onSaveFileForm(state, action) {
  return Object.assign(state, {
    files: action.payload.success ? action.payload.files : state.files,
  })
}

const saveFormsUsfcr = createAsyncThunk(
  'globalSettings/saveFormsUsfcr',
  params => {
    return application.call(API.GLOBAL_SETTINGS.SAVE_FORMS, params)
  }
)
function onSaveFormsUsfcr(state, action) {
  const forms = action.payload.forms
  const adjustmentForms = action.payload.adjustmentForms

  return Object.assign(state, {
    forms: forms ? forms : state.forms,
    adjustmentForms: adjustmentForms ? adjustmentForms : state.adjustmentForms,
  })
}

const changeStatusForm = createAsyncThunk(
  'globalSettings/changeStatusForm',
  params => {
    return application.call(API.GLOBAL_SETTINGS.CHANGE_STATUS_FORM, params)
  }
)
function onChangeStatusForm(state, action) {
  let formsCopy = []
  let key = 'forms'
  if (action.payload.type === 'principal') {
    formsCopy = [...state.forms]
  } else {
    formsCopy = [...state.adjustmentForms]
    key = 'adjustmentForms'
  }

  const index = formsCopy.findIndex(i => i.id === action.payload.id)
  formsCopy[index].active = action.payload.active

  return Object.assign(state, {
    [key]: formsCopy,
  })
}

const deleteForm = createAsyncThunk('globalSettings/deleteForm', params => {
  return application.call(API.GLOBAL_SETTINGS.DELETE_FORM, params)
})
function onDeleteForm(state, action) {
  let formsCopy = []
  let key = 'forms'
  if (action.payload.type === 'principal') {
    formsCopy = [...state.forms]
  } else {
    formsCopy = [...state.adjustmentForms]
    key = 'adjustmentForms'
  }

  const index = formsCopy.findIndex(i => i.id === action.payload.id)
  formsCopy.splice(index, 1)

  return Object.assign(state, {
    [key]: formsCopy,
  })
}

const deleteFileForm = createAsyncThunk(
  'globalSettings/deleteFileForm',
  params => {
    return application.call(API.GLOBAL_SETTINGS.DELETE_FILE_FORM, params)
  }
)
function onDeleteFileForm(state, action) {
  let filesCopy = []
  filesCopy = [...state.files]
  const index = filesCopy.findIndex(i => i === action.payload.file)
  filesCopy.splice(index, 1)

  return Object.assign(state, {
    files: filesCopy,
  })
}

const saveExportLimits = createAsyncThunk(
  'globalSettings/saveExportLimits',
  params => {
    return application.call(API.GLOBAL_SETTINGS.SAVE_EXPORT_LIMITS, params)
  }
)
function onSaveExportLimits(state, action) {
  return Object.assign(state, {
    exportedItemsAtOnce: action.payload.exportedItemsAtOnce,
    monthlyExportsLimit: action.payload.monthlyExportsLimit,
  })
}

const saveCalendlyConfiguration = createAsyncThunk(
  'globalSettings/saveCalendlyConfiguration',
  params => {
    return application.call(
      API.GLOBAL_SETTINGS.SAVE_CALENDLY_CONFIGURATION,
      params
    )
  }
)
function onSaveCalendlyConfiguration(state, action) {
  return Object.assign(state, {
    calendly: action.payload.calendly,
  })
}

const changeToPendingFeatures = state =>
  Object.assign(state, {
    pendingFeatures: true,
  })

const changeToPendingDetail = state =>
  Object.assign(state, {
    pendingDetail: true,
  })

//Actions
export const actions = {
  getFeatures,
  saveFeatures,
  getFormstackForms,
  changeStatusForm,
  deleteForm,
  getAdjustmentForms,
  getKeepTrashedNotifications,
  changeCurrentIdsForms,
  changeFormstackForms,
  saveFormsUsfcr,
  changeCurrentFormDetail,
  saveFileForm,
  deleteFileForm,
  getGlobalSettings,
  updateApiKey,
  updateKeepTrashedNotification,
  updateAcceptQuoteRequests,
  saveExportLimits,
  saveCalendlyConfiguration,
  getDefaultSAPRequests,
  changeDefaultSAPRequests,
  saveDefaultSAPRequests,
  deleteTemplateSAP,
  getDefaultSAMRequests,
  changeDefaultSAMRequests,
  saveDefaultSAMRequests,
  deleteTemplateSAM,
}

export default createReducer(INITIAL_STATE, builder => {
  builder
    .addCase(getFeatures.pending, changeToPendingFeatures)
    .addCase(getAdjustmentForms.pending, changeToPendingDetail)
    .addCase(getFormstackForms.fulfilled, onGetFormstackForms)
    .addCase(getFeatures.fulfilled, onGetFeatures)
    .addCase(saveFeatures.fulfilled, onSaveFeatures)
    .addCase(changeStatusForm.fulfilled, onChangeStatusForm)
    .addCase(deleteForm.fulfilled, onDeleteForm)
    .addCase(getAdjustmentForms.fulfilled, onGetAdjustmentForms)
    .addCase(
      getKeepTrashedNotifications.fulfilled,
      onGetKeepTrashedNotifications
    )
    .addCase(changeCurrentIdsForms, onChangeCurrentIdsForms)
    .addCase(changeFormstackForms, onChangeFormstackForms)
    .addCase(changeCurrentFormDetail, onChangeCurrentFormDetail)
    .addCase(saveFormsUsfcr.fulfilled, onSaveFormsUsfcr)
    .addCase(saveFileForm.fulfilled, onSaveFileForm)
    .addCase(deleteFileForm.fulfilled, onDeleteFileForm)
    .addCase(getGlobalSettings.fulfilled, onGetGlobalSettings)
    .addCase(updateApiKey.fulfilled, onUpdateApiKey)
    .addCase(
      updateKeepTrashedNotification.fulfilled,
      onUpdateKeepTrashedNotification
    )
    .addCase(updateAcceptQuoteRequests.fulfilled, onUpdateAcceptQuoteRequests)
    .addCase(saveExportLimits.fulfilled, onSaveExportLimits)
    .addCase(saveCalendlyConfiguration.fulfilled, onSaveCalendlyConfiguration)
    .addCase(getDefaultSAPRequests.fulfilled, onGetDefaultSAPRequests)
    .addCase(changeDefaultSAPRequests, onChangeDefaultSAPRequests)
    .addCase(saveDefaultSAPRequests.fulfilled, onSaveDefaultSAPRequests)
    .addCase(deleteTemplateSAP.fulfilled, onDeleteTemplateSAP)
    .addCase(getDefaultSAMRequests.fulfilled, onGetDefaultSAMRequests)
    .addCase(changeDefaultSAMRequests, onChangeDefaultSAMRequests)
    .addCase(saveDefaultSAMRequests.fulfilled, onSaveDefaultSAMRequests)
    .addCase(deleteTemplateSAM.fulfilled, onDeleteTemplateSAM)
})
